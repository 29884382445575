<template>
  <v-img
    src="@/assets/bg_landing.jpg"
    lazy-src="@/assets/bg_landing_small.jpg"
    height="100%"
    width="100%"
  >
    <div
      class="d-flex flex-column mx-15 justify-center align-end"
      style="height: 100%"
    >
      <div class="d-flex flex-column">
        <div
          style="color:#4b40d7; font-size: 40px; padding-bottom: 30px; display: flex; flex-direction: row; align-items: center; font-weight: 500;"
        >
          <div class="pr-3">
            <v-img
              src="@/assets/white_logo.svg"
              width="30"
              height="30"
              contain
            />
          </div>
          aKinder™Chat
        </div>
        <span style="color:#26206c; font-size: 30px; font-weight:600;">
          A Shared Commitment
        </span>
        <span style="color:#26206c; font-size: 30px; font-weight:600;"
          >To Solving Community</span
        >
        <span style="color:#26206c; font-size: 30px; font-weight:600;"
          >Challenges!</span
        >
        <div class="flex-column d-none d-sm-flex">
          <v-btn width="200" color="primary" class="mt-10" @click="goToLogin"
            >Log in</v-btn
          >
          <v-btn
            width="200"
            color="primary"
            class="mt-4 d-none"
            @click="goToRegister"
            text
            >Register</v-btn
          >
        </div>
        <div class="d-flex flex-column d-sm-none align-center">
          <v-btn width="200" color="primary" class="mt-10" @click="goToLogin"
            >Log in</v-btn
          >
          <v-btn
            width="200"
            color="primary"
            class="mt-4 d-none"
            @click="goToRegister"
            text
            >Register</v-btn
          >
        </div>
      </div>
    </div>
  </v-img>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", {
      isAuthenticated: "isAuthenticated",
    }),
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "home" });
    }
  },
};
</script>
