import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import request from "./modules/request";
import family from "./modules/family";
import chat from "./modules/chat";
import variable from "./modules/variable";
import filter from "./modules/filter";
import notification from "./modules/notification";
import checkedin from "./modules/checkedin";
import promo_code from "./modules/prome_code";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    request,
    chat,
    variable,
    filter,
    notification,
    family,
    checkedin,
    promo_code,
  },
  state: {},
  mutations: {},
  actions: {},
});
