import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/getStarted/Landing.vue";
import store from "@/store";

Vue.use(VueRouter);
const DEFAULT_TITLE = "aKinder™Chat";
const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
    props: true,
    meta: { requiresAuth: false, title: "Healthy Communities" },
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "families" */ "../views/admin/Families.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/Notifications.vue"
      ),
    props: true,
  },
  {
    path: "/chat",
    name: "chat",
    meta: { requiresAuth: true, title: "Support | Healthy Communities" },
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/chat/Chat.vue"),
    children: [
      {
        path: "group",
        name: "group-chat",
        component: () => import("../views/chat/ChatGroupDetails.vue"),
      },
      {
        path: ":id",
        name: "chat-details",
        component: () => import("../views/chat/ChatDetails.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/mchat",
    name: "mchat-details",
    meta: { requiresAuth: true, title: "Support | Healthy Communities" },
    component: () =>
      import(
        /* webpackChunkName: "mchat-details" */ "../views/chat/ChatMobileDetails.vue"
      ),
    props: true,
  },
  {
    path: "/families",
    name: "families",
    meta: {
      requiresAuth: true,
      title: "Family Managers | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "families" */ "../views/admin/Families.vue"),
    props: true,
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      requiresAuth: true,
      title: "Settings | Healthy Communities",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "questions" */ "../views/admin/Settings.vue"),
    children: [
      {
        path: "account",
        name: "account-settings",
        meta: {
          requiresAuth: true,
          title: "Account Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsAcount.vue"),
      },
      {
        path: "notification",
        name: "notification-settings",
        meta: {
          requiresAuth: true,
          title: "Notification Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsNotification.vue"),
      },
      {
        path: "password",
        name: "password-settings",
        meta: {
          requiresAuth: true,
          title: "Password Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsPassword.vue"),
      },
      {
        path: "variables",
        name: "variable-settings",
        meta: {
          requiresAuth: true,
          title: "Variable Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsVariables.vue"),
      },
      {
        path: "categories",
        name: "category-settings",
        meta: {
          requiresAuth: true,
          title: "Category Settings | Healthy Communities",
        },
        component: () => import("../views/admin/SettingsCategories.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false, title: "Login | Healthy Communities" },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/getStarted/Login.vue"),
    props: true,
  },
  {
    path: "/register",
    name: "register",
    meta: { requiresAuth: false, title: "Register | Healthy Communities" },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/getStarted/Register.vue"
      ),
    props: true,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    meta: {
      requiresAuth: false,
      title: "Forgot Password | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/getStarted/ForgotPassword.vue"
      ),
    props: true,
  },
  {
    path: "/verifycode",
    name: "verifycode",
    meta: {
      requiresAuth: false,
      title: "Verification Code | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/getStarted/VerifyCode.vue"
      ),
    props: true,
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {
      requiresAuth: false,
      title: "Reset Password | Healthy Communities",
    },
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/getStarted/ResetPassword.vue"
      ),
    props: true,
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      requiresAuth: false,
      title: "Privacy and Policy | Healthy Communities",
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Policy.vue"),
    props: true,
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    meta: {
      requiresAuth: false,
      title: "Terms of Use (EULA) | Healthy Communities",
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/TermsOfUse.vue"),
    props: true,
  },
  {
    path: "/404",
    name: "404",
    meta: { requiresAuth: false, title: "Not Found | Healthy Communities" },
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/linker",
    name: "linker",
    component: () =>
      import(/* webpackChunkName: "linker" */ "../views/Linker.vue"),
    props: true,
  },
  {
    path: "/*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters["auth/isAuthenticated"]) {
      if (
        to.meta.permission &&
        !to.meta.permission.includes(store.getters["auth/getType"])
      ) {
        router.push({ name: "home" });
      } else {
        if (from.name == "intake") {
          if (store.getters["intake/unsavedChanges"]) {
            const answer = window.confirm("Changes you made may not be saved.");
            if (answer) {
              next();
            }
          } else next();
        } else next();
        document.title = to.meta.title || DEFAULT_TITLE;
      }
    } else {
      next("login");
    }
  } else {
    next();
  }
});

export default router;
